<!--This is the Admin page, with the list of all available users/customers. -->
<template>
    <b-container fluid class="admin-container py-4">
        <b-row class="mb-4">
            <b-col
                class="d-flex flex-column align-items-start flex-grow flex-sm-row align-items-sm-center"
            >
                <template v-if="hasUsersPermission">
                    <router-link :to="{name: 'UserList'}" class="text-primary-light">
                        <h1 class="mb-0">{{ t('user') }}</h1>
                    </router-link>
                </template>

                <template v-if="hasCustomersPermission">
                    <div class="divider" v-if="hasUsersPermission"></div>
                    <router-link :to="{name: 'CustomerList'}" class="text-primary-light">
                        <h1 class="mb-0">{{ t('customer') }}</h1>
                    </router-link>
                </template>
            </b-col>
        </b-row>
        <router-view />
        <p class="font-weight-lighter mt-2">
            <small>{{ t('disclaimer') }} </small>
        </p>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';

export default {
    name: 'AdminPage',
    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapGetters({
            permissions: 'permissions/permissions',
        }),
        hasUsersPermission() {
            return this.checkPermissions({
                [PERMISSIONS.USERS]: PERMISSIONS_VALUES.READ_TYPES,
            });
        },
        hasCustomersPermission() {
            return this.checkPermissions({
                [PERMISSIONS.CUSTOMER_ADMIN]: PERMISSIONS_VALUES.WRITE_TYPES,
            });
        },
    },
    methods: {
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/variables';
@import '@/assets/css/typography';

::v-deep .router-link-active {
    text-decoration: underline;
    color: $primary;
}
.divider {
    width: 4px;
    background: $gray-light;
    height: 100%;
    margin: 0 30px 0 40px;
}

@media (max-width: 576px) {
    .divider {
        width: 50%;
        height: 4px;
        margin: 30px 0 20px 0;
    }
}
</style>
